<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <pca-region-save />
    </b-tab>
    <!--/ general tab -->

    <!-- general tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Region Connect') }}
          </span>
      </template>

      <pca-region-connected />
    </b-tab>
    <!--/ general tab -->

  </b-tabs>
</template>

<script>
import PcaRegionSave from './PcaRegionSave.vue'
import PcaRegionConnected from './PcaRegionConnected.vue'

export default {
  components: {
    PcaRegionSave,
    PcaRegionConnected,
  },
}
</script>
