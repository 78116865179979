<template>
  <b-card>
      <!-- form -->
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            ref="refListTable"
            :fields="fields"
            :items="regionList"
          >
            <template #cell(connected)="data">
              <b-form-checkbox
                v-model="data.item.isConnected"
                @change="onToggleConnect(data.item)"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </template>
          </b-table>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
import ApiService from '@/common/api.service'
// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  data() {
    return {
      fields: [
        {
          // A regular column with custom formatter
          key: 'connected',
          label: 'connected',
        },
        {
          key: 'title',
          label: 'title',
        },
      ],
      regionList: [],
    }
  },
  methods: {
    fetchRegions() {
      ApiService.get('PresetRegion/getall', {}, this)
      .then(response => {
        if (response.statusCode === 200) {
          this.regionList = response.result.filter(x => x.presetRegionType === 1);
          this.fetchRegionConnect();
        }
      })
    },
    fetchRegionConnect() {
      const paramId = this.$router.currentRoute.params.id;
      ApiService.get(`PresetPcaRegionConnect/${paramId}`, {}, this)
        .then(responseConnected => {
          if (responseConnected) {
            this.regionList.forEach(value => {
              const item = responseConnected.result.find(x => x.regionId === value.id);
              if (item) {
                value.isConnected = true;
              } else {
                value.isConnected = false;
              }
            });
            this.$refs.refListTable.refresh();
          }
        });
    },

    onToggleConnect(item) {
      if (this.$router.currentRoute.params.id === '0') {
        AlertService.error(this, 'First Save Pca Region')
        return;
      }
      const url = (item.isConnected) ? 'PresetPcaRegionConnect' : 'PresetPcaRegionConnect/delete';
      const query = {
        pcaRegionId: this.$router.currentRoute.params.id,
        regionId: item.id,
      };

      ApiService.post(url, query, this)
        .then(response => {
          this.showResponse(response);
        });
    },

    showResponse(response) {
      if (response.statusCode === 200) {
        this.$toast({
          component: ToastificationSuccess,
          position: 'top-right',
          props: { text: this.$t(response.message) },
        });
      } else {
        AlertService.error(this, this.$t(response.message))
      }
    },
  },
  created() {
    this.fetchRegions();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
